define('@fortawesome/pro-regular-svg-icons', ['exports'], function (exports) { 'use strict';

  var faBars = {
    prefix: 'far',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM448 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24z"]
  };
  var faRotateLeft = {
    prefix: 'far',
    iconName: 'rotate-left',
    icon: [512, 512, ["rotate-back", "rotate-backward", "undo-alt"], "f2ea", "M140.8 122.9C171.7 96.2 212 80 256 80c97.2 0 176 78.8 176 176s-78.8 176-176 176c-39.7 0-76.2-13.1-105.6-35.2c-10.6-8-25.6-5.8-33.6 4.8s-5.8 25.6 4.8 33.6C159 463.3 205.6 480 256 480c123.7 0 224-100.3 224-224S379.7 32 256 32c-57.3 0-109.6 21.5-149.2 56.9L76.3 58.3C69.7 51.7 60.7 48 51.3 48C31.8 48 16 63.8 16 83.3V200c0 13.3 10.7 24 24 24H156.7c19.5 0 35.3-15.8 35.3-35.3c0-9.4-3.7-18.3-10.3-25l-40.8-40.8zm-76.8-9L126.1 176H64V113.9z"]
  };
  var faMoonCloud = {
    prefix: 'far',
    iconName: 'moon-cloud',
    icon: [640, 512, [], "f754", "M635.2 416.6c-2.6 2.5-5.2 5-7.9 7.3c-4.5 4-9.1 7.8-13.9 11.4c-1.8 1.4-3.7 2.7-5.5 4C571.5 465 527.2 480 479.5 480c-60.9 0-116.1-24.4-156.4-64c11.6-12 20.3-26.9 25-43.4C380.2 409 427.2 432 479.5 432c13.3 0 26.2-1.5 38.6-4.3C430.5 397.4 367.6 314 367.6 216c0-43 12.1-83.2 33.1-117.3C343.4 127.6 304 187.1 304 256l0 .3c-13.9-8.9-30.1-14.6-47.5-16c7.6-110.9 95.8-199.6 206.3-207.7c2.3-.2 4.6-.3 6.9-.4c3.2-.1 6.5-.2 9.8-.2c2.7 0 5.5 0 8.2 .1c3.6 .1 7.2 .3 10.7 .6c7 .6 12.8 5.7 14.3 12.5s-1.6 13.9-7.7 17.3c-3.2 1.8-6.4 3.8-9.6 5.8c-4.3 2.8-8.5 5.8-12.5 9c-1.1 .8-2.1 1.7-3.2 2.6c-39.2 32.3-64.1 81.2-64.1 136c0 97.1 78.5 175.8 175.2 176l.3 0c1.2 0 2.5 0 3.7 0c5.2-.1 10.3-.4 15.3-1c3.7-.4 7.4-.9 11.1-1.6c6.9-1.2 13.8 2.2 17 8.5s1.9 13.8-3.1 18.7zM64 416c-35.3 0-64-28.7-64-64s28.7-64 64-64c.5 0 1.1 0 1.6 0c7.4-36.5 39.7-64 78.4-64c35 0 64.8 22.5 75.6 53.8c8.7-3.7 18.3-5.8 28.4-5.8c39.8 0 72 32.2 72 72s-32.2 72-72 72H64z"]
  };
  var faMagnifyingGlass = {
    prefix: 'far',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"]
  };
  var faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };
  var faTimes = faXmark;
  var faSunBright = {
    prefix: 'far',
    iconName: 'sun-bright',
    icon: [512, 512, ["sun-alt"], "e28f", "M280 24V88c0 13.3-10.7 24-24 24s-24-10.7-24-24V24c0-13.3 10.7-24 24-24s24 10.7 24 24zm157 84.9l-45.3 45.3c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L403.1 75c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM108.9 75l45.3 45.3c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L75 108.9c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM24 232H88c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24zm400 0h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H424c-13.3 0-24-10.7-24-24s10.7-24 24-24zM154.2 391.8L108.9 437c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l45.3-45.3c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zm237.6-33.9L437 403.1c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-45.3-45.3c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM280 424v64c0 13.3-10.7 24-24 24s-24-10.7-24-24V424c0-13.3 10.7-24 24-24s24 10.7 24 24zm40-168a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zm-176 0a112 112 0 1 1 224 0 112 112 0 1 1 -224 0z"]
  };

  exports.faBars = faBars;
  exports.faMagnifyingGlass = faMagnifyingGlass;
  exports.faMoonCloud = faMoonCloud;
  exports.faRotateLeft = faRotateLeft;
  exports.faSunBright = faSunBright;
  exports.faTimes = faTimes;

  Object.defineProperty(exports, '__esModule', { value: true });

});
